import styles from '@styles/Home.module.css';
import { Source_Sans_3 } from 'next/font/google';
import Input from '@components/misc/Input';
import { useCallback, useEffect, useState } from 'react';
import Button from '@components/misc/Button';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { EmailRegEx } from '@lib/utils';

const header = Source_Sans_3({
  weight: '700',
  subsets: ['latin'],
});

const description = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

interface Props {
  color: 'white' | 'black';
}

export default function IndexContact({ color }: Props) {
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validate = useCallback((): boolean => {
    const newErrors: Record<string, string> = {};
    let isValid = true;

    if (email.trim().length == 0) {
      newErrors.email = 'Du måste fylla i din e-postadress';
      isValid = false;
    } else {
      if (EmailRegEx.test(email)) {
        newErrors.email = '';
      } else {
        newErrors.email = 'Du måste fylla i en giltig e-postadress';
        isValid = false;
      }
    }

    if (phone.trim().length == 0) {
      newErrors.phone = 'Du måste fylla i ditt telefonnummer';
      isValid = false;
    } else {
      if (phone.length == 10) {
        newErrors.phone = '';
      } else {
        newErrors.phone = 'Du måste fylla i ett giltigt telefonnummer';
        isValid = false;
      }
    }

    if (name.trim().length == 0) {
      newErrors.name = 'Du måste fylla i ditt namn';
      isValid = false;
    } else {
      newErrors.name = '';
    }

    setErrors(newErrors);
    return isValid;
  }, [email, name, phone]);

  useEffect(() => {
    validate();
  }, [email, phone, name, validate]);

  function submit(): void {
    const isValid: boolean = validate();

    if (isValid == true) {
      fetch('https://api.zerio.se/simpleContact', {
        body: JSON.stringify({
          email: email,
          name: name,
          phone: phone,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      setEmail('');
      setName('');
      setPhone('');
      enqueueSnackbar(
        'Ditt mejl har nu skickats och kommer besvaras inom kort. Tack för att du kontaktade oss!',
        { variant: 'success', autoHideDuration: 15000 }
      );
    }
  }

  return (
    <>
      <div className={`${styles.contact} ${styles[color]}`}>
        <div className={styles.contactTexts}>
          <h2 className={`${header.className} ${styles.contactHeader}`}>
            Kontakta oss redan idag
          </h2>
          <h3
            className={`${description.className} ${styles.contactDescription}`}
          >
            Ta första steget för att skapa något fantastiskt. Vi gör dina
            drömmar till en verklighet
          </h3>
        </div>

        <div className={styles.contactForm}>
          <Input
            inputType="text"
            placeholder="Ditt namn"
            className={styles.contactInput}
            color={color == 'black' ? 'white' : 'black'}
            setValue={setName}
            value={name}
            errorText={errors.name}
            ariaLabel="Namn"
          />

          <Input
            inputType="text"
            placeholder="Mejladdress"
            className={styles.contactInput}
            color={color == 'black' ? 'white' : 'black'}
            setValue={setEmail}
            value={email}
            errorText={errors.email}
            ariaLabel="Mejl"
          />

          <Input
            inputType="number"
            placeholder="Telefonnummer"
            className={styles.contactInput}
            color={color == 'black' ? 'white' : 'black'}
            setValue={setPhone}
            value={phone}
            errorText={errors.phone}
            ariaLabel="Telefonnummer"
          />

          <Button color="white" clicked={submit}>
            Skicka in
          </Button>
        </div>
      </div>
      <SnackbarProvider maxSnack={3} className={description.className} />
    </>
  );
}
