import styles from '@styles/Home.module.css';
import { Source_Sans_3 } from 'next/font/google';
import { FaqList, type InfoItem } from '@lib/utils';

const header = Source_Sans_3({
  weight: '700',
  subsets: ['latin'],
});

const header2 = Source_Sans_3({
  weight: '600',
  subsets: ['latin'],
});

const description = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

export default function IndexFAQ() {
  return (
    <div className={styles.faq} itemScope itemType="https://schema.org/FAQPage">
      <h2 className={`${styles.faqHeader} ${header.className}`}>
        Vad kan man förvänta sig?
      </h2>

      <div className={styles.faqList}>
        {FaqList.map((faq: InfoItem, idx: number) => {
          return (
            <div
              className={styles.faqItem}
              key={idx}
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question"
            >
              <h3
                className={`${header2.className} ${styles.faqItemHeader}`}
                itemProp="name"
              >
                {faq.header}
              </h3>
              <div
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
                className={styles.faqItemDescription}
              >
                <p
                  itemProp="text"
                  className={description.className}
                  dangerouslySetInnerHTML={{
                    __html: faq.description,
                  }}
                ></p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
