import styles from '@styles/Layout.module.css';

interface Props {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  itemScope?: boolean;
  itemType?: string;
}

export default function Layout({
  children,
  className,
  itemScope,
  itemType,
}: Props) {
  return (
    <main
      className={`${styles.main}${className ? ' ' + className : ''}`}
      itemScope={itemScope}
      itemType={itemType}
    >
      {children != undefined ? children : <></>}
    </main>
  );
}
