import Image from 'next/image';
import styles from '@styles/Home.module.css';
import { Source_Sans_3 } from 'next/font/google';
import Button from '@components/misc/Button';
import { useRouter, type NextRouter } from 'next/router';

const header = Source_Sans_3({
  weight: '700',
  subsets: ['latin'],
});

const description = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

export default function IndexMain() {
  const router: NextRouter = useRouter();

  return (
    <div className={styles.hero}>
      <div className={styles.info}>
        <h1 className={`${styles.heroText} ${header.className}`}>
          En hjälpande hand igenom den digitala världen
        </h1>
        <p className={`${styles.heroDescription} ${description.className}`}>
          Vi har sedan början av 2023 hjälpt flertalet företag att växa och
          utvecklas i den digitala världen. Vi har hjälpt dem att utveckla deras
          digitala identitet samt skapa en digital plattform som hjälper dem att
          nå sina mål.
          <br />
          <br />
          Visste du att ~36% utav nya företag inte har en hemsida? Vi hjälper
          dig att skapa en hemsida som är anpassad för dina kunder och som
          hjälper dig att nå ut till mer personer än någonsin.
        </p>

        <Button
          color="black"
          className={styles.button}
          clicked={() => {
            router.push('/contact');
          }}
        >
          Kontakta oss
        </Button>
      </div>
      <Image
        src="/images/hero.webp"
        alt="Hero Image"
        width={500}
        height={335}
        className={styles.heroImage}
      />
    </div>
  );
}
