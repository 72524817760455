import { type NextRouter, useRouter } from 'next/router';
import styles from '@styles/Home.module.css';
import { Source_Sans_3 } from 'next/font/google';
import Input from '@components/misc/Input';
import Button from '@components/misc/Button';
import { useState, useEffect, useCallback } from 'react';
import { URLRegEx } from '@lib/utils';

const header = Source_Sans_3({
  weight: '700',
  subsets: ['latin'],
});

const description = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

export default function IndexSiteSpeed() {
  const router: NextRouter = useRouter();
  const [site, setSite] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validate = useCallback((): boolean => {
    const newErrors: Record<string, string> = {};
    let isValid = true;

    if (site.trim().length == 0) {
      isValid = false;
      newErrors.site = 'Du måste fylla i en webbadress';
    } else {
      if (URLRegEx.test(site)) {
        newErrors.site = '';
      } else {
        isValid = false;
        newErrors.site = 'Du måste fylla i en giltig webbadress';
      }
    }

    setErrors(newErrors);

    return isValid;
  }, [site]);

  useEffect(() => {
    validate();
  }, [site, validate]);

  function testSubmit(): void {
    const isValid: boolean = validate();

    if (isValid == true) {
      router.push('/siteSpeed?url=' + site);
    }
  }

  return (
    <div className={styles.siteSpeed}>
      <div className={styles.inner}>
        <div className={styles.texts}>
          <h2 className={`${header.className} ${styles.header}`}>
            Blixtsnabb Sidhastighet
          </h2>

          <p className={`${description.className} ${styles.description}`}>
            Sidhastighet diskuteras oftast när man pratar om Google SEO, eller
            med andra ord, sökmotor optimering. Att ha en snabb sida och bra
            sidhastighets mätningar är vitalt då det används när Google ska
            välja vilka resultat som visas högst i sökrankningarna.
            <br />
            <br />
            Vi kan stolt säga att vi garanterar sidhastigheter inom den 90:de
            eller högre precentilen.
          </p>
        </div>

        <div className={styles.form}>
          <h3 className={`${header.className} ${styles.header}`}>
            Testa din nuvarande hemsida här
          </h3>

          <Input
            inputType="text"
            className={styles.siteInput}
            placeholder="Ange en webbaddress"
            setValue={setSite}
            value={site}
            errorText={errors.site}
            ariaLabel="Webbaddress"
            color="white"
          />

          <Button color="white" clicked={testSubmit}>
            TESTA HEMSIDA
          </Button>
        </div>
      </div>
    </div>
  );
}
