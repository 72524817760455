import styles from '@styles/Home.module.css';
import { Source_Sans_3 } from 'next/font/google';
import { useWindowSize, type WindowSize, type News } from '@lib/utils';
import Image from 'next/image';
import Button from '@components/misc/Button';
import { type NextRouter, useRouter } from 'next/router';

const header = Source_Sans_3({
  weight: '700',
  subsets: ['latin'],
});

const description = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

interface PropsStruct {
  news: News[];
}

export default function IndexNews({ news }: PropsStruct) {
  const size: WindowSize = useWindowSize();
  const router: NextRouter = useRouter();

  return (
    <div className={styles.news}>
      <h2 className={`${header.className} ${styles.newsHeader}`}>Aktuellt</h2>

      <div className={styles.latestNews}>
        {size.width > 775 ? (
          <>
            <div
              className={`${styles.newsItem} ${styles.two}`}
              onClick={() => {
                router.push('/news/' + news[1].attributes.uid);
              }}
              itemScope
              itemType="https://schema.org/NewsArticle"
            >
              {news[1] != undefined ? (
                <>
                  <Image
                    src={
                      'https://strapi.zerio.se' +
                      news[1].attributes.image.data.attributes.url
                    }
                    width={350}
                    height={350}
                    quality={0.5}
                    alt={'Bild för ' + news[1].attributes.header}
                    className={styles.newsItemImage}
                    itemProp="thumbnailUrl"
                    itemRef={
                      'https://strapi.zerio.se' +
                      news[1].attributes.image.data.attributes.url
                    }
                  />
                  <h3
                    className={`${styles.newsItemHeader} ${header.className}`}
                    itemProp="headline"
                  >
                    {news[1].attributes.header}
                  </h3>
                  <h4
                    className={`${styles.newsItemDate} ${description.className}`}
                    itemProp="datePublished"
                  >
                    {news[1].attributes.publishedAt}
                  </h4>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`${styles.newsItem} ${styles.one}`}
              onClick={() => {
                router.push('/news/' + news[0].attributes.uid);
              }}
              itemScope
              itemType="https://schema.org/NewsArticle"
            >
              {news[0] != undefined ? (
                <>
                  <Image
                    src={
                      'https://strapi.zerio.se' +
                      news[0].attributes.image.data.attributes.url
                    }
                    quality={0.5}
                    width={400}
                    height={400}
                    alt={'Bild för ' + news[0].attributes.header}
                    className={styles.newsItemImage}
                    itemProp="thumbnailUrl"
                    itemRef={
                      'https://strapi.zerio.se' +
                      news[0].attributes.image.data.attributes.url
                    }
                  />
                  <h3
                    className={`${styles.newsItemHeader} ${header.className}`}
                    itemProp="headline"
                  >
                    {news[0].attributes.header}
                  </h3>
                  <h4
                    className={`${styles.newsItemDate} ${description.className}`}
                    itemProp="datePublished"
                  >
                    {news[0].attributes.publishedAt}
                  </h4>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`${styles.newsItem} ${styles.three}`}
              onClick={() => {
                router.push('/news/' + news[2].attributes.uid);
              }}
              itemScope
              itemType="https://schema.org/NewsArticle"
            >
              {news[2] != undefined ? (
                <>
                  <Image
                    src={
                      'https://strapi.zerio.se' +
                      news[2].attributes.image.data.attributes.url
                    }
                    quality={0.5}
                    width={350}
                    height={350}
                    alt={'Bild för ' + news[2].attributes.header}
                    className={styles.newsItemImage}
                    itemProp="thumbnailUrl"
                    itemRef={
                      'https://strapi.zerio.se' +
                      news[2].attributes.image.data.attributes.url
                    }
                  />
                  <h3
                    className={`${styles.newsItemHeader} ${header.className}`}
                    itemProp="headline"
                  >
                    {news[2].attributes.header}
                  </h3>
                  <h4
                    className={`${styles.newsItemDate} ${description.className}`}
                    itemProp="datePublished"
                  >
                    {news[2].attributes.publishedAt}
                  </h4>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={`${styles.newsItem} ${styles.one}`}
              onClick={() => {
                router.push('/news/' + news[0].attributes.uid);
              }}
              itemScope
              itemType="https://schema.org/NewsArticle"
            >
              {news[0] != undefined ? (
                <>
                  <Image
                    src={
                      'https://strapi.zerio.se' +
                      news[0].attributes.image.data.attributes.url
                    }
                    quality={0.5}
                    width={400}
                    height={400}
                    alt={'Bild för ' + news[0].attributes.header}
                    className={styles.newsItemImage}
                    itemProp="thumbnailUrl"
                    itemRef={
                      'https://strapi.zerio.se' +
                      news[0].attributes.image.data.attributes.url
                    }
                  />
                  <h3
                    className={`${styles.newsItemHeader} ${header.className}`}
                    itemProp="headline"
                  >
                    {news[0].attributes.header}
                  </h3>
                  <h4
                    className={`${styles.newsItemDate} ${description.className}`}
                    itemProp="datePublished"
                  >
                    {news[0].attributes.publishedAt}
                  </h4>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`${styles.newsItem} ${styles.two}`}
              onClick={() => {
                router.push('/news/' + news[1].attributes.uid);
              }}
              itemScope
              itemType="https://schema.org/NewsArticle"
            >
              {news[1] != undefined ? (
                <>
                  <Image
                    src={
                      'https://strapi.zerio.se' +
                      news[1].attributes.image.data.attributes.url
                    }
                    width={350}
                    height={350}
                    quality={0.5}
                    alt={'Bild för ' + news[1].attributes.header}
                    className={styles.newsItemImage}
                    itemProp="thumbnailUrl"
                    itemRef={
                      'https://strapi.zerio.se' +
                      news[1].attributes.image.data.attributes.url
                    }
                  />
                  <h3
                    className={`${styles.newsItemHeader} ${header.className}`}
                    itemProp="headline"
                  >
                    {news[1].attributes.header}
                  </h3>
                  <h4
                    className={`${styles.newsItemDate} ${description.className}`}
                    itemProp="datePublished"
                  >
                    {news[1].attributes.publishedAt}
                  </h4>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`${styles.newsItem} ${styles.three}`}
              onClick={() => {
                router.push('/news/' + news[2].attributes.uid);
              }}
              itemScope
              itemType="https://schema.org/NewsArticle"
            >
              {news[2] != undefined ? (
                <>
                  <Image
                    src={
                      'https://strapi.zerio.se' +
                      news[2].attributes.image.data.attributes.url
                    }
                    quality={0.5}
                    width={350}
                    height={350}
                    alt={'Bild för ' + news[2].attributes.header}
                    className={styles.newsItemImage}
                    itemProp="thumbnailUrl"
                    itemRef={
                      'https://strapi.zerio.se' +
                      news[2].attributes.image.data.attributes.url
                    }
                  />
                  <h3
                    className={`${styles.newsItemHeader} ${header.className}`}
                    itemProp="headline"
                  >
                    {news[2].attributes.header}
                  </h3>
                  <h4
                    className={`${styles.newsItemDate} ${description.className}`}
                    itemProp="datePublished"
                  >
                    {news[2].attributes.publishedAt}
                  </h4>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>

      <Button
        color="black"
        className={styles.newsSeeMoreButton}
        clicked={() => {
          router.push('/news/all');
        }}
      >
        SE MER INLÄGG
      </Button>
    </div>
  );
}
