import styles from '@styles/Misc.module.css';
import { Source_Sans_3 } from 'next/font/google';
import { MouseEventHandler } from 'react';

interface PropsStruct {
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  color: 'black' | 'white';
  clicked?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const header = Source_Sans_3({
  weight: '700',
  subsets: ['latin'],
});

export default function Button({
  children,
  className,
  clicked,
  color,
  disabled,
}: PropsStruct) {
  return (
    <button
      className={`${styles.button} ${header.className} ${styles[color]}${
        className !== undefined ? ' ' + className : ''
      }`}
      disabled={disabled != undefined ? disabled : false}
      onClick={clicked}
    >
      {children != undefined ? children : ''}
    </button>
  );
}
