import Head from 'next/head';
import styles from '@styles/Home.module.css';
import Layout from '@components/Layout';
import IndexMain from '@components/index/Main';
import IndexFAQ from '@components/index/FAQ';
import IndexCustomers from '@components/index/Customers';
import IndexContact from '@components/Contact';
import IndexNews from '@components/index/News';
import { formatDate, type News, sortNews } from '@lib/utils';
import { fetchData } from '@lib/strapi';
import IndexSiteSpeed from '@components/index/SiteSpeed';
import { NextRouter, useRouter } from 'next/router';
import { useEffect } from 'react';

interface PropsStruct {
  news: News[];
}

export async function getStaticProps() {
  const data = await fetchData('/newss', {
    populate: {
      header: '*',
      image: '*',
      uid: '*',
    },
  });

  return {
    props: {
      news: sortNews(data.data).map((news: News) => {
        news.attributes.publishedAt = formatDate(news.attributes.publishedAt);

        return news;
      }),
    },
  };
}

export default function Home({ news }: PropsStruct) {
  const router: NextRouter = useRouter();
  const { ref } = router.query;

  function gtag(
    type: string,
    tagName: string,
    args: Record<string, string | number | string[] | boolean>,
  ) {
    // @ts-ignore
    window.dataLayer.push([type, tagName, args]);
  }

  useEffect(() => {
    if (router.isReady == true && ref !== undefined) {
      gtag('event', 'ref', {
        referrer: ref,
      });
    }
  }, [ref, router.isReady]);

  return (
    <>
      <Head>
        <title>Webbyrå baserad i Jönköping | Zerio</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://zerio.se" />
      </Head>
      <Layout className={styles.container}>
        <IndexMain />
        <IndexFAQ />
        <IndexCustomers />
        <IndexContact color="black" />
        <IndexNews news={news} />
        <IndexSiteSpeed />
      </Layout>
    </>
  );
}
