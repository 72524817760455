import styles from '@styles/Home.module.css';
import { Customers, type Customer } from '@lib/utils';
import Image from 'next/image';
import Link from 'next/link';

export default function IndexCustomers() {
  return (
    <div className={styles.customers}>
      {Customers.map((customer: Customer, idx: number) => {
        return (
          <Link 
            href={customer.link}
            title={"Läs om " + customer.text}
            rel="noopener"
            target="_blank"
            aria-label={customer.text}
            aria-describedby="opens in a new window"
            key={idx}
          >
            <Image
              src={`/customers/${customer.image}`}
              alt={customer.text}
              width={customer.width ? customer.width : 200}
              height={(customer.width ? customer.width : 200) / customer.height}
              className={styles.customerImage}
              style={{
                height: `${(customer.width ? customer.width : 200) / customer.height}px`,
                width: `${customer.width ? customer.width : 200}px`
              }}
            />
          </Link>
        );
      })}
    </div>
  );
}
