import styles from '@styles/Misc.module.css';
import { Source_Sans_3 } from 'next/font/google';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface PropStruct {
  inputType: 'number' | 'text';
  placeholder: string;
  className?: string;
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
  errorText?: string;
  disabled?: boolean;
  ariaLabel?: string;
  color: 'black' | 'white';
}

const description = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

export default function Input({
  inputType,
  placeholder,
  className,
  setValue,
  errorText,
  disabled,
  value,
  ariaLabel,
  color,
}: PropStruct) {
  return (
    <div
      className={`${styles.input} ${styles[color]}${
        errorText != undefined && errorText.trim().length != 0
          ? ' ' + styles.error
          : ''
      }${className !== undefined ? ' ' + className : ''}`}
    >
      <input
        aria-label={ariaLabel}
        type={inputType}
        placeholder={placeholder}
        className={`${description.className}`}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
        disabled={disabled != undefined ? disabled : false}
        value={value}
      />
      {errorText != undefined && errorText.trim().length != 0 ? (
        <p className={`${description.className} ${styles.inputErrorText}`}>
          {errorText}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}
